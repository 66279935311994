var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mega-card", { attrs: { title: "Round Position" } }, [
    _c(
      "div",
      {
        staticClass: "card-media ratio",
        style: { paddingBottom: _vm.padding + "%" }
      },
      [
        _c(
          "gmap-map",
          {
            staticClass: "fill-parent",
            staticStyle: { position: "absolute" },
            attrs: {
              center: { lat: _vm.lat, lng: _vm.lng },
              zoom: 17,
              "map-type-id": "terrain",
              options: {
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
              }
            }
          },
          [
            _c("gmap-marker", {
              attrs: { position: { lat: _vm.lat, lng: _vm.lng } }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("p", { staticClass: "mb-0 d-inline mr-2" }, [
        _vm._v(_vm._s(_vm.place))
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted" }, [_vm._v(_vm._s(_vm.address))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }